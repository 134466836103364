<template>
  <div class="filter">
    <ArticleAccordion>
      <!-- CATEGORY FILTER -->
      <ArticleAccordionItem
        v-if="widgetConfigTypeDef.categoryFilterEnabled"
        :text="
          $t(
            'components.pageheader.search.item.dropdown.filter.filter-poi.categories.text'
          )
        "
      >
        <div class="content horizontalPadding">
          <ArticleAccordion>
            <template v-for="(items, key, index) in categories" :key="index">
              <ArticleAccordionItem
                v-if="key !== emptyGroupName"
                :text="key"
                :small="true"
              >
                <InputCheckboxList
                  v-model="selectedItems.categories"
                  :items="items"
                  name="key"
                />
              </ArticleAccordionItem>

              <InputCheckboxList
                v-else
                v-model="selectedItems.categories"
                :items="items"
                name="key"
              />
            </template>
          </ArticleAccordion>
        </div>
      </ArticleAccordionItem>

      <!-- LOCATION FILTER -->
      <ArticleAccordionItem
        v-if="widgetConfigTypeDef.locationFilterEnabled"
        :text="
          $t(
            'components.pageheader.search.item.dropdown.filter.filter-poi.location.text'
          )
        "
      >
        <div class="content">
          <PageheaderSearchItemDropdownFilterDistance
            v-model="selectedItems.location.location"
          />

          <!-- <Input-CheckboxList
            label="Verkehrsinfrastruktur (im Umkreis von 2km)"
            :items="infrastructures"
            name="infrastructures"
          /> -->
        </div>
      </ArticleAccordionItem>

      <!-- OPENING HOURS FILTER -->
      <ArticleAccordionItem
        v-if="widgetConfigTypeDef.openingHoursFilterEnabled"
        :text="
          $t(
            'components.pageheader.search.item.dropdown.filter.filter-poi.openingHours.text'
          )
        "
      >
        <div class="content">
          <InputCalendarQuick
            :selected="selectedItems.date.selectedQuickdateItem"
            @set-date="(date) => handleQuickdate(date)"
          />

          <InputCalendarAdditionalDayTime
            v-model="selectedItems.date.additional.dayTime"
            label="Tageszeit"
            :items="dayTime"
            name="dayTime"
          />
        </div>
      </ArticleAccordionItem>

      <!--  <Article-Accordion-Item text="Auslastung">
        TODO: implement occupancy picker
      </Article-Accordion-Item> -->

      <!-- ATTRIBUTES FILTER -->
      <ArticleAccordionItem
        v-if="widgetConfigTypeDef.criterionFilterEnabled"
        :text="
          t(
            'components.pageheader.search.item.dropdown.filter.filter-poi.attributes.text'
          )
        "
      >
        <InputPickerMulti
          v-model="selectedItems.attributes"
          :items="attributes"
          name="attributes"
        />
      </ArticleAccordionItem>
    </ArticleAccordion>

    <PageheaderSearchItemDropdownFilterFooter
      :content="
        t(
          'components.pageheader.search.item.dropdown.filter.filter-poi.footer',
          totalCount
        )
      "
      @apply="emit('apply')"
    />
  </div>
</template>

<script lang="ts" setup>
import dayjs from 'dayjs';
import type {
  Category,
  WidgetConfigPoiDef,
} from '../../../../../../gql/schema';
import { PoiFilterProperty } from '../../../../../../models/BaseFilterInput';
import type { Nullable } from '../../../../../../models/CustomUtilityTypes';
import { WeekdayIndex } from '../../../../../../models/Weekdays';
import type { DateFilter, FilterModel } from '../../../models';
import type { LocationType } from './Distance/models';

const { t } = useI18n();

const emit = defineEmits(['apply']);

const model = defineModel<FilterModel>();
const openingHoursModel = defineModel<DateFilter>('date');

const widgetConfig = await useWidgetConfig();
const widgetConfigTypeDef = useWidgetTypeConfig(
  widgetConfig
) as Ref<WidgetConfigPoiDef>;

const searchStore = useSearchStore();

const searchStateFromCurrentFormData = mapFilterToSearchModel(
  () => searchStore.state.search,
  openingHoursModel,
  model
);

const { totalCount, categoryFacets, criteriaFacets } = fetchPoiFilterFacets(
  widgetConfig,
  computed(
    () =>
      toValue(buildPoiFilter(widgetConfig, searchStateFromCurrentFormData))
        ?.filter
  ),
  computed(
    () =>
      toValue(
        buildPoiFilter(widgetConfig, searchStateFromCurrentFormData, [
          PoiFilterProperty.CATEGORY,
        ])
      )?.filter
  ),
  toValue(widgetConfigTypeDef)?.categoryFilterProductlines?.map((_) => _.id),
  computed(
    () =>
      toValue(
        buildPoiFilter(widgetConfig, searchStateFromCurrentFormData, [
          PoiFilterProperty.ATTRIBUTE,
        ])
      )?.filter
  ),
  toValue(widgetConfigTypeDef)?.criterionFilterProductlines?.map((_) => _.id)
);

const dayTime = [
  { label: '6-12 Uhr', icon: 'mdi:sunrise', value: 'morning' },
  {
    label: '12-18 Uhr',
    icon: 'material-symbols:clear-day',
    value: 'afternoon',
  },
  { label: '18-24 Uhr', icon: 'mdi:weather-sunset-down', value: 'evening' },
];

const categories = computed(() =>
  groupCategoriesByParent(model.value?.categories, toValue(categoryFacets))
);
const attributes = computed(() => {
  const facetData = toValue(criteriaFacets);

  return model.value?.attributes
    .map((attribute) => {
      return {
        label: attribute.i18nName + ' (' + (facetData[attribute.id] ?? 0) + ')',
        value: attribute.id,
      };
    })
    .filter((attribute): attribute is { label: string; value: number } => {
      return isDefined(attribute.label) && isDefined(attribute.value);
    });
});

const handleQuickdate = (date: string[]) => {
  if (isEmpty(date)) {
    return;
  }

  if (date.length === 1) {
    date.push(date[0]);
  } else if (date.length > 2) {
    date = date.slice(0, 2);
  }
  selectedItems.value.date.date = date;
};

const selectedItems = ref<{
  categories: number[];
  attributes: number[];
  particularities: number[];
  infrastructures: number[];
  location: {
    infrastructures: number[];
    location: {
      id: number | undefined;
      type: LocationType | undefined;
      name: string | undefined;
      longitude: number | undefined;
      latitude: number | undefined;
    };
  };
  date: {
    date: string[];
    selectedQuickdateItem: 0 | 1 | 2 | undefined;
    additional: {
      categories: [];
      dayTime: string[];
    };
  };
}>({
  categories: [],
  attributes: [],
  particularities: [],
  infrastructures: [],
  location: {
    infrastructures: [],
    location: {
      id: undefined,
      type: undefined,
      name: undefined,
      longitude: undefined,
      latitude: undefined,
    },
  },
  date: {
    date: [],
    selectedQuickdateItem: undefined,
    additional: {
      categories: [],
      dayTime: [],
    },
  },
});

// Watch for changes in the model and update selectedItems accordingly (model -> selectedItems)
watch(
  model,
  (newValue) => {
    if (newValue) {
      selectedItems.value.categories = newValue.categories
        .filter((category) => category.selected)
        .map((category) => category.id);
      selectedItems.value.attributes = newValue.attributes
        .filter((attribute) => attribute.selected)
        .map((attribute) => attribute.id);
    }
  },
  { deep: true, immediate: true }
);
watch(
  openingHoursModel,
  (newValue) => {
    if (newValue) {
      selectedItems.value.date.date = newValue.date.selectedDateRange;
      selectedItems.value.date.selectedQuickdateItem = determineQuickdateIndex(
        newValue.date.selectedDateRange
      );
      selectedItems.value.date.additional.dayTime = newValue.additional.dayTime;
    }
  },
  { deep: true, immediate: true }
);

// Watch for changes in selectedItems and update the model accordingly (selectedItems -> model)
watch(
  selectedItems,
  (newValue) => {
    if (model.value) {
      model.value.categories.forEach((category) => {
        category.selected = newValue.categories.includes(category.id);
      });
      model.value.attributes.forEach((attribute) => {
        attribute.selected = newValue.attributes.includes(attribute.id);
      });
      model.value.location.location.id = newValue.location.location.id;
      model.value.location.location.type = newValue.location.location.type;
      model.value.location.location.name = newValue.location.location.name;
      model.value.location.location.longitude =
        newValue.location.location.longitude;
      model.value.location.location.latitude =
        newValue.location.location.latitude;
    }
    if (openingHoursModel.value) {
      openingHoursModel.value.date.selectedDateRange = newValue.date.date;
      openingHoursModel.value.additional.dayTime =
        newValue.date.additional.dayTime;
    }
  },
  { deep: true }
);

const emptyGroupName = 'none';

function groupCategoriesByParent(
  categories: Nullable<Category[]>,
  facetData: Record<number, number>
) {
  if (isEmpty(categories)) {
    return {};
  }

  const groupedCategories = categories
    .map((category) => {
      const label =
        category.i18nName + ' (' + (facetData[category.id] ?? 0) + ')';
      const value = category.id;
      const group = category.parent?.i18nName || emptyGroupName;
      return { label, value, group };
    })
    .filter((category) => category.label && category.value && category.group)
    .sort((a, b) => a.label!.localeCompare(b.label!))
    .reduce<{
      [key: string]: { label: string; value: string; group?: string }[];
    }>((acc, category) => {
      if (category.group) {
        if (!acc[category.group]) {
          acc[category.group] = [];
        }
        acc[category.group].push(category);
      } else {
        if (!acc[emptyGroupName]) {
          acc[emptyGroupName] = [];
        }
        acc[emptyGroupName].push(category);
      }
      return acc;
    }, {});

  return groupedCategories;
}

function determineQuickdateIndex(
  date: Nullable<string[]>
): 0 | 1 | 2 | undefined {
  if (isEmpty(date) || date!.length !== 2) {
    return undefined;
  }

  const [startDate, endDate] = date!.map((d) => dayjs(d));
  const today = dayjs().startOf('day');
  const tomorrow = today.add(1, 'day');

  if (startDate.isSame(endDate, 'day')) {
    if (startDate.isSame(today, 'day')) {
      return 0;
    } else if (startDate.isSame(tomorrow, 'day')) {
      return 1;
    }
  } else if (
    startDate.day() === WeekdayIndex.Saturday &&
    endDate.day() === WeekdayIndex.Sunday
  ) {
    return 2;
  }

  return undefined;
}
</script>

<style src="./Filter.scss" scoped lang="scss"></style>
