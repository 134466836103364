import dayjs from 'dayjs';
import type {
  DateFilter,
  FilterModel,
} from '../components/Pageheader/Search/models';
import type { SearchModel } from '../stores/searchStore';

export default (
  formModel_fulltextsearch: MaybeRefOrGetter<string>,
  formModel_date: MaybeRefOrGetter<DateFilter>,
  formModel_filter: MaybeRefOrGetter<FilterModel>
): Ref<SearchModel> => {
  return computed(() => {
    const formObject: Partial<Record<keyof SearchModel, unknown>> = {
      search: toValue(formModel_fulltextsearch),
      categories: toValue(formModel_filter)
        .categories?.filter(
          (category) => category && category.id && category.selected
        )
        .map((category) => category.id!),
      criteria: toValue(formModel_filter)
        .attributes?.filter(
          (attribute) => attribute && attribute.id && attribute.selected
        )
        .map((attribute) => attribute.id!),
      dateFrom:
        toValue(formModel_date).date.selectedDateRange[0] ||
        toValue(formModel_date).date.pendingDate,
      dateTo:
        toValue(formModel_date).date.selectedDateRange[1] ||
        toValue(formModel_date).date.pendingDate,
      daytime: toValue(formModel_date).additional.dayTime,
      onlyFree:
        toValue(formModel_date).additional.categories.includes(
          'free-of-charge'
        ),
      hasSingleEvent:
        toValue(formModel_filter).particularities?.includes('single-events'),
      duration: toValue(formModel_filter).tour?.duration,
      length: toValue(formModel_filter).tour?.length,
      difficulties: toValue(formModel_filter).tour?.difficulties,
      locationId: toValue(formModel_filter).location.location?.id,
      locationType: toValue(formModel_filter).location.location?.type,
      locationName: toValue(formModel_filter).location.location?.name,
      latitude: toValue(formModel_filter).location.location?.latitude,
      longitude: toValue(formModel_filter).location.location?.longitude,
    };

    const parsedState: SearchModel = {} as SearchModel;

    Object.keys(formObject).forEach((key) => {
      const typedKey = key as keyof SearchModel;
      // if (!(typedKey in state)) {
      //   console.error(`Key ${typedKey} not found in state`);
      //   return;
      // }
      const value = toValue(formObject[typedKey]);
      switch (typedKey) {
        case 'search':
          parsedState.search = !isEmpty(value)
            ? (value as string).split(' ')
            : [];
          break;
        case 'dateFrom':
        case 'dateTo':
          parsedState[typedKey] = !isEmpty(value)
            ? dayjs(value as string).format('YYYY-MM-DD')
            : undefined;
          break;
        case 'daytime':
          if (Array.isArray(value)) {
            const validDaytimes = ['morning', 'afternoon', 'evening'];
            parsedState.daytime = (value as string[])
              .filter((time) => validDaytimes.includes(time))
              .sort(
                (a, b) => validDaytimes.indexOf(a) - validDaytimes.indexOf(b)
              );
          } else {
            parsedState.daytime = [];
          }
          break;
        case 'latitude':
        case 'longitude':
        case 'radius':
        case 'locationId':
          parsedState[typedKey] =
            isEmpty(value) || isNaN(Number(value)) ? undefined : Number(value);
          break;
        case 'onlyFree':
        case 'hasSingleEvent':
          parsedState[typedKey] = value === true ? true : undefined;
          break;
        case 'duration':
        case 'length':
          parsedState[typedKey] = !isEmpty(value)
            ? (value as [string, string]).map(Number).every((num) => num === 0)
              ? undefined
              : ((value as [string, string]).map(Number) as [number, number])
            : undefined;
          break;
        case 'difficulties':
          parsedState[typedKey] = !isEmpty(value)
            ? (value as number[]).map(Number)
            : [];
          break;
        default:
          (parsedState[typedKey] as unknown) = value;
      }
    });

    return parsedState;
  });
};
