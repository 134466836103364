<template>
  <form
    class="search"
    :class="`search-${whlModuleType}`"
    @submit.prevent=""
    @reset="handleReset"
  >
    <PageheaderSearchItemTerm
      v-if="widgetConfigTypeDef?.whatSearchEnabled"
      v-model="formModel_fulltextsearch"
      class="term"
      item-id="term"
      :active-item="activeItem"
      :placeholder="termPlaceholder ?? ''"
      @keyup.enter="handleToggle('term', 'close', true)"
      @activate="handleToggle('term', 'open')"
      @deactivate="handleToggle('term', 'close')"
      @close="handleToggle('term', 'close')"
    />

    <PageheaderSearchItemDate
      v-if="
        whlModuleType === WhlModuleType.Event &&
        (widgetConfigTypeDef as RawWidgetConfigEventDefFragment)
          ?.whenSearchEnabled
      "
      v-model="formModel_date"
      class="date"
      item-id="date"
      :active-item="activeItem"
      @activate="handleToggle('date', 'open')"
      @deactivate="handleToggle('date', 'close')"
      @toggle="handleToggle('date')"
      @close="handleToggle('date', 'close')"
      @apply="handleToggle('date', 'close', true)"
    />

    <PageheaderSearchItemFilter
      v-if="widgetConfigTypeDef?.filterEnabled"
      v-model="formModel_filter"
      v-model:date="formModel_date"
      class="filter"
      :item-id="filterItemId"
      :whl-module-type="whlModuleType ?? WhlModuleType.Event"
      :active-item="activeItem"
      @activate="handleToggle(filterItemId, 'open')"
      @deactivate="handleToggle(filterItemId, 'close')"
      @toggle="handleToggle(filterItemId)"
      @close="handleToggle(filterItemId, 'close')"
      @apply="handleToggle(filterItemId, 'close', true)"
    />
  </form>
</template>

<script lang="ts" setup>
import type { RawWidgetConfigEventDefFragment } from '../../../gql/fragments/__generated/RawWidgetConfigEventDef';
import type { RawWidgetConfigPoiDefFragment } from '../../../gql/fragments/__generated/RawWidgetConfigPoiDef';
import type { RawWidgetConfigTourDefFragment } from '../../../gql/fragments/__generated/RawWidgetConfigTourDef';
import type { Nullable } from '../../../models/CustomUtilityTypes';
import { WhlModuleType } from '../../../models/WhlModuleType';
import { useGlobalStore } from '../../../stores/globalStore';
import type { SearchModel } from '../../../stores/searchStore';
import type {
  AttributeFilter,
  CategoryFilter,
  DateFilter,
  FilterModel,
} from './models';

const { t } = useI18n();
const widgetConfig = await useWidgetConfig();
const widgetConfigTypeDef = useWidgetTypeConfig(widgetConfig);
const whlModuleType = useWhlModuleType();
const searchStore = useSearchStore();

const refreshFilterModel = (): void => {
  formModel_filter.value.categories = constructCategoryFilterObject(
    toValue(whlModuleType),
    toValue(widgetConfigTypeDef)
  );
  formModel_filter.value.attributes = constructAttributeFilterObject(
    toValue(whlModuleType),
    toValue(widgetConfigTypeDef)
  );
};

watch(whlModuleType, refreshFilterModel);
watch(widgetConfigTypeDef, refreshFilterModel);

const DEBOUNCE_DELAY = 500;

// TODO: This will be the pattern with Vue 3.5
/* const { type, filterItemId = "filter" } = defineProps<{
  type: WhlModuleType;
  filterItemId?: string;
}>(); */
const props = withDefaults(
  defineProps<{
    filterItemId?: string;
  }>(),
  {
    filterItemId: 'filter',
  }
);
const filterItemId = computed(() => props.filterItemId);

const activeItem = ref<string>('');

const formModel_fulltextsearch = ref<string>('');
const formModel_date = ref<DateFilter>({
  date: {
    selectedDateRange: [],
    pendingDate: '',
  },
  additional: {
    categories: [],
    dayTime: [],
  },
});

const formModel_filter = ref<FilterModel>({
  categories: constructCategoryFilterObject(
    toValue(whlModuleType),
    toValue(widgetConfigTypeDef)
  ),
  attributes: constructAttributeFilterObject(
    toValue(whlModuleType),
    toValue(widgetConfigTypeDef)
  ),
  location: {
    location: {
      id: undefined,
      type: undefined,
      name: undefined,
      latitude: undefined,
      longitude: undefined,
    },
    infrastructures: [
      // { label: 'Barrierefrei', value: '1' },
      // { label: 'Parkplatz', value: '2' },
      // { label: 'WC', value: '3' },
    ],
  },
  particularities: [
    //{ label: 'nur einmalige Events anzeigen', value: '1' },
    // { label: 'nur Events mit freiem Eintritt', value: '2' },
    // { label: 'Veranstaltungstipps zuerst anzeigen', value: '3' },
  ],
  tour: {
    length: undefined,
    duration: undefined,
    difficulties: [],
  },
});

const termPlaceholder = computed(() => {
  switch (toValue(whlModuleType)) {
    case WhlModuleType.Poi:
      return t('common.sights');
    case WhlModuleType.Event:
      return t('common.events');
    case WhlModuleType.Tour:
      return t('common.tours');
  }
  return undefined;
});

const stateFromFormModel = mapFilterToSearchModel(
  formModel_fulltextsearch,
  formModel_date,
  formModel_filter
);

const handleSubmit = (): SearchModel => {
  return searchStore.updateState(
    toValue(stateFromFormModel),
    toValue(widgetConfig)
  );
};

const handleToggle = (
  itemId: string,
  forceState?: 'close' | 'open',
  submit = false
): void => {
  const globalStore = useGlobalStore();

  if (submit) {
    handleSubmit();
  }

  if (forceState) {
    activeItem.value = forceState === 'close' ? '' : itemId;
    globalStore.setModalState(forceState);
    return;
  }

  activeItem.value = activeItem.value === itemId ? '' : itemId;
  if (itemId !== 'term') {
    globalStore.toggleModalState();
  }
};

const handleReset = (): void => {
  searchStore.resetState();
};

const debouncedHandleSubmit = useDebounceFn(handleSubmit, DEBOUNCE_DELAY);

// Trigger automatic handle submit on formModel changes
watch(
  [formModel_fulltextsearch],
  () => {
    debouncedHandleSubmit();
  },
  { deep: true }
);

// Watch the searchStore and update formModel values
watch(
  searchStore,
  (newState) => {
    formModel_fulltextsearch.value = newState.state.search.join(' ');
    formModel_filter.value.categories.forEach((category) => {
      category.selected = newState.state.categories.includes(category.id);
    });
    formModel_filter.value.attributes.forEach((attribute) => {
      attribute.selected = newState.state.criteria.includes(attribute.id);
    });
    formModel_date.value.date.selectedDateRange = [
      newState.state.dateFrom,
      newState.state.dateTo,
    ];
    formModel_date.value.additional.dayTime = newState.state.daytime;
    formModel_filter.value.tour.duration = newState.state.duration;
    formModel_filter.value.tour.length = newState.state.length;
    formModel_filter.value.location.location.id = newState.state.locationId;
    formModel_filter.value.location.location.type = newState.state.locationType;
    formModel_filter.value.location.location.name = newState.state.locationName;
  },
  { deep: true, immediate: true }
);

function constructCategoryFilterObject(
  module: Nullable<WhlModuleType>,
  widgetConfigTypeDef: Nullable<
    | RawWidgetConfigPoiDefFragment
    | RawWidgetConfigEventDefFragment
    | RawWidgetConfigTourDefFragment
  >
): CategoryFilter[] {
  if (!module || !widgetConfigTypeDef) {
    return [];
  }

  let criteria: CategoryFilter[] = [];

  if (module === WhlModuleType.Poi) {
    criteria =
      (
        widgetConfigTypeDef as RawWidgetConfigPoiDefFragment
      ).categoryFilterProductlines
        ?.filter((attribute) => attribute.id && attribute.i18nName)
        .map((attribute) => ({
          id: attribute.id!,
          i18nName: attribute.i18nName!,
          selected: false,
        })) ?? [];
  } else if (module === WhlModuleType.Event) {
    criteria =
      (
        widgetConfigTypeDef as RawWidgetConfigEventDefFragment
      ).categoryFilterCategories
        ?.filter((attribute) => attribute.id && attribute.i18nName)
        .map((attribute) => ({
          id: attribute.id!,
          i18nName: attribute.i18nName!,
          selected: false,
        })) ?? [];
  } else if (module === WhlModuleType.Tour) {
    criteria =
      (
        widgetConfigTypeDef as RawWidgetConfigTourDefFragment
      ).activityFilterTourCategories
        ?.filter((attribute) => attribute.id && attribute.i18nName)
        .map((attribute) => ({
          id: attribute.id!,
          i18nName: attribute.i18nName!,
          selected: false,
        })) ?? [];
  }
  return criteria;
}

function constructAttributeFilterObject(
  module: Nullable<WhlModuleType>,
  widgetConfigTypeDef: Nullable<
    | RawWidgetConfigPoiDefFragment
    | RawWidgetConfigEventDefFragment
    | RawWidgetConfigTourDefFragment
  >
): AttributeFilter[] {
  if (!module || !widgetConfigTypeDef) {
    return [];
  }

  let attributes: AttributeFilter[] = [];

  if (module === WhlModuleType.Poi) {
    attributes =
      (
        widgetConfigTypeDef as RawWidgetConfigPoiDefFragment
      ).criterionFilterProductlines
        ?.filter((attribute) => attribute.id && attribute.i18nName)
        .map((attribute) => ({
          id: attribute.id!,
          i18nName: attribute.i18nName!,
          selected: false,
        })) ?? [];
  } else if (module === WhlModuleType.Event) {
    attributes =
      (
        widgetConfigTypeDef as RawWidgetConfigEventDefFragment
      ).criterionFilterCriteria
        ?.filter((attribute) => attribute.id && attribute.i18nName)
        .map((attribute) => ({
          id: attribute.id!,
          i18nName: attribute.i18nName!,
          selected: false,
        })) ?? [];
  } else if (module === WhlModuleType.Tour) {
    attributes =
      (
        widgetConfigTypeDef as RawWidgetConfigTourDefFragment
      ).attributeFilterTourAttributes
        ?.filter((attribute) => attribute.id && attribute.i18nName)
        .map((attribute) => ({
          id: attribute.id!,
          i18nName: attribute.i18nName!,
          selected: false,
        })) ?? [];
  }

  return attributes;
}
</script>

<style scoped lang="scss">
@import './Search.scss';
</style>
