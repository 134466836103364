import type { AddressbaseFilter, WidgetConfig } from '../gql/schema';
import type {
  BaseFilterInput,
  PoiFilterProperty,
} from '../models/BaseFilterInput';

export default (
  widgetConfig: MaybeRefOrGetter<WidgetConfig>,
  userSearchModel: MaybeRefOrGetter<SearchModel>,
  ignoreFields?: MaybeRefOrGetter<PoiFilterProperty[]>
): Ref<BaseFilterInput<AddressbaseFilter>> => {
  const baseFilter = buildPoiBaseFilter(widgetConfig);
  const userFilter = buildPoiUserFilter(userSearchModel, ignoreFields);
  const appearanceFilter = buildAppearanceFilterFromWidgetConfig(widgetConfig);

  return computed((): BaseFilterInput<AddressbaseFilter> => {
    return {
      filter: {
        and: [toValue(baseFilter), toValue(userFilter)],
      },
      appearance: toValue(appearanceFilter),
    };
  });
};
