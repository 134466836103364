import { useQuery } from '@urql/vue';
import type { MaybeRef } from 'vue';
import type { RawWidgetConfigFragment } from '../gql/fragments/__generated/RawWidgetConfig';
import {
  EventFilterFacetsDocument,
  type EventFilterFacetsQuery,
  type EventFilterFacetsQueryVariables,
} from '../gql/queries/__generated/EventFilterFacets';
import type { EventFilter } from '../gql/schema';

export default (
  widgetConfig: MaybeRef<RawWidgetConfigFragment | null>,
  filter: MaybeRefOrGetter<EventFilter>,
  filterWithoutCategories: MaybeRefOrGetter<EventFilter>,
  allowedCategories?: MaybeRefOrGetter<number[]>,
  filterWithoutCriteria: MaybeRefOrGetter<EventFilter>,
  allowedCriteria?: MaybeRefOrGetter<number[]>
) => {
  const { locale } = useI18n();

  const { data } = useQuery<
    EventFilterFacetsQuery,
    EventFilterFacetsQueryVariables
  >({
    query: EventFilterFacetsDocument,
    variables: {
      language: locale,
      appearance: buildAppearanceFilterFromWidgetConfig(widgetConfig),
      filter: filter,
      filterWithoutCategories: filterWithoutCategories,
      filterWithoutCriteria: filterWithoutCriteria,
      allowedCategories: allowedCategories,
      allowedCriteria: allowedCriteria,
    },
  });

  const totalCount = computed(() => {
    return data.value?.totalRecords?.pagination?.totalRecords ?? -1;
  });

  const categoryFacets = computed((): Record<number, number> => {
    return data.value?.categoryFacets?.facets?.fields[0]?.facets?.reduce(
      (acc, facet) => {
        acc[facet.data.id] = facet.count;
        return acc;
      },
      {}
    );
  });

  const criteriaFacets = computed((): Record<number, number> => {
    return data.value?.criteriaFacets?.facets?.fields[0]?.facets?.reduce(
      (acc, facet) => {
        acc[facet.data.id] = facet.count;
        return acc;
      },
      {}
    );
  });

  return {
    totalCount,
    categoryFacets,
    criteriaFacets,
  };
};
